import React from "react";
import TextField from "@mui/material/TextField";
import { api, googleEvent } from "../../api";
import { CardOption, BookingTimeModal, BookingPricingCard } from "../../components/";
import Button from "@mui/material/Button";
import * as Images from "../../themes/images";
import "./resumebooking.css";
import Loading from "../../components/Loading";
import moment from "moment";
import { Autocomplete } from "@mui/material";

class ResumeBooking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      completeLoading: false,
      cartId: null,
      cartData: null,
      errorMessage: "",
      selectedVehicleType: "Sedan",
      serviceOptions: null,
      selectedService: null,
      selectedDateTime: null,
      openBookingModal: false,
      locationAvailability: [],
      availibilityInterval: 15,
      userBillingDetails: {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        carRego: "",
      },
      errorField: {},
      serviceSpecificCapacities: [],
      discount: 0,
      selectedLocation: null,
      carTypeOptions: [
        {
          name: "Sedan",
          isSelected: false,
        },
        {
          name: "Wagon / SUV",
          isSelected: false,
        },
        {
          name: "4WD / 7 Seater",
          isSelected: false,
        },
        {
          name: "RV / Caravan",
          isSelected: false,
        },
      ],
      locationArray: [],
      locations: [],
    };
    this.fetchAbandonedCart = this.fetchAbandonedCart.bind(this);
    this.changeServiceSelected = this.changeServiceSelected.bind(this);
    this.toggleDateModal = this.toggleDateModal.bind(this);
    this.handleSelectedTime = this.handleSelectedTime.bind(this);
    this.completeBooking = this.completeBooking.bind(this);
    this.getLocations = this.getLocations.bind(this);
    this.getLocationPricing = this.getLocationPricing.bind(this);
    this.handleSelectedDate = this.handleSelectedDate.bind(this);
    this.changeTypeSelected = this.changeTypeSelected.bind(this);
    this.handleBookingInputs = this.handleBookingInputs.bind(this);
    this.removeWhieteSpace = this.removeWhieteSpace.bind(this);
    this.handleSelectedLocation = this.handleSelectedLocation.bind(this);
    this.handleBookingModal = this.handleBookingModal.bind(this);
  }

  async fetchAbandonedCart(cartId) {
    try {
      const res = await api(`cart/resumeAbandonedCart/${cartId}`, "GET");
      if (res.status === 200 && res.data.success) {

        console.log("res.data: ", res.data);
        const { cart, selectedLocation } = res.data;
        if(cart){
            const sessionData = cart.sessionData;
            if(selectedLocation && selectedLocation._id){
                await this.getLocationPricing(selectedLocation._id)
                this.setState({
                    selectedLocation: selectedLocation
                })

                let data = {
                  date: moment().format("YYYY-MM-DD"),
                  selectedLocation: selectedLocation._id,
                };

                let availability = await api("location/availability", "POST", data);
                if (availability.status === 200) {
                    if (availability.data.success) {
                        let locationAvailability = availability.data.locationAvailability;
                        this.setState({
                            locationAvailability: locationAvailability,
                            availibilityInterval: availability.data.interval,
                            serviceSpecificCapacities: availability.data.serviceSpecificCapacities,
                        });
                    }
                } else {
                    console.log("failed to get availability: ", availability);
                }
            }

            let state = {
                cartData: cart,
                errorMessage: "",
                loading: false,
                userBillingDetails: {
                    firstName: sessionData.bookingUserDetails.firstName ? sessionData.bookingUserDetails.firstName : "",
                    lastName: sessionData.bookingUserDetails.lastName ? sessionData.bookingUserDetails.lastName : "",
                    phone: sessionData.bookingUserDetails.phone ? sessionData.bookingUserDetails.phone : "",
                    email: sessionData.bookingUserDetails.email ? sessionData.bookingUserDetails.email : "",
                    carRego: sessionData.bookingUserDetails.carRego ? sessionData.bookingUserDetails.carRego : "",
                }
            }

            if(sessionData.selectedVehicleType) state.selectedVehicleType = sessionData.selectedVehicleType;
            if(sessionData.selectedBookingTime) state.selectedDateTime= sessionData.selectedBookingTime;

            this.setState(state);
            // Fire Google Event on page load
            googleEvent("user_resume_booking_page_view", { cartId: cart._id });
        }
      } else {
        this.setState({ errorMessage: "Cart not found. Please start a new booking." });
      }
    } catch (error) {
      console.error("Error fetching cart:", error);
      this.setState({ errorMessage: "An error occurred. Please try again later." });
    }
  }

  async getLocationPricing(locationId) {
    const pricing = await api(`location/${locationId}/pricing`, "GET", null);
    if (pricing.status == 200) {
      var resp = pricing.data;
      if (resp.success) {
        console.log("loc pricing", resp.pricing);
        this.setState({
            serviceOptions: resp.pricing,
        });
      } else {
        alert(resp.message);
      }
    }
  }

  async completeBooking() {
    const { 
        selectedVehicleType, 
        selectedService, 
        selectedDateTime, 
        selectedLocation,
        userBillingDetails,
        cartData
    } = this.state;

    let errors = {};

    if (!selectedLocation || !selectedLocation._id) {
        alert("Please select a location.");
        return;
    }

    // Validation to ensure all required 'selected' state variables are set
    if (!selectedVehicleType || selectedVehicleType.trim() === "") {
        alert("Please select a vehicle type.");
        return;
    }

    if (!selectedService || selectedService.trim() === "") {
        alert("Please select a service.");
        return;
    }

    if (!selectedDateTime || selectedDateTime.trim() === "") {
        alert("Please select a booking date and time.");
        return;
    }

    // Validation for userBillingDetails
    const requiredBillingFields = ["firstName", "lastName", "phone", "email", "carRego"];
    for (const field of requiredBillingFields) {
        if ( !userBillingDetails[field] || userBillingDetails[field].trim() === "") {
            errors[field] = `Please enter a valid ${field.replace(/([A-Z])/g, " $1").toLowerCase()}.`;
        }
    }

    if (Object.keys(errors).length > 0) {
        this.setState({ errorField: errors });
        return;  
    } else {
        this.setState({ errorField: {} });
    }

    this.setState({
        completeLoading: true
    })

    const dataToSend = {
        selectedDateTime, 
        selectedLocation, 
        selectedService,
        selectedVehicleType, 
        userBillingDetails, 
        userId: cartData.sessionData.userId,
        utmData: cartData.sessionData.utmData,
        discountInfo: {
            couponCode: cartData.sessionData.couponCode,
            hasCoupon: cartData.sessionData.hasCoupon,
            hasOffer: cartData.sessionData.hasOffer,
            hasReward: cartData.sessionData.hasReward,
            rewardCode: cartData.sessionData.rewardCode
        },
        abandonedCart: cartData
    };

    console.log("complete booking: ", dataToSend);


    try {
      const res = await api(`booking/resume/confirmed`, "POST", { ...dataToSend });
      if (res.status === 200 && res.data.success) {
        // Fire Google Event on booking confirmed
        // googleEvent("booking_confirmed", { bookingNumber: res.data.bookingNumber });
        console.log(" send data success " , res.data);

        window.location.href = `/resume-success?bookingKey=${res.data.booking.bookingKey}`;

      } else {
        alert("Booking failed. Please try again.");
        this.setState({
            completeLoading: false
        })
      }
    } catch (error) {
        console.error("Error completing booking:", error);
        alert("An error occurred. Please try again later.");
        this.setState({
            completeLoading: false
        })
    }
    
  }

  removeWhieteSpace(val) {
    val = val.target;
    this.setState((prevState) => {
      let bookingUserDetails = Object.assign({}, prevState.userBillingDetails); // creating copy of state variable jasper
      bookingUserDetails[val.name] = val.value.replaceAll(" ", ""); // update the name property, assign a new value
      return { bookingUserDetails };
    });
  }

  changeServiceSelected(value) {
    this.setState((prevState) => ({
      serviceOptions: prevState.serviceOptions.map((obj) =>
        obj.name === value
          ? { ...obj, isSelected: true }
          : { ...obj, isSelected: false }
      ),
      selectedService: value,
    }));
  }

  handleBookingModal(){
    if(this.state.selectedLocation){
        this.toggleDateModal(!this.props.openBookingModal)
    } else {
        alert("Please select a location before attempting to select a time.");
    }
  }

  toggleDateModal() {
    this.setState((prevState) => ({
      openBookingModal: !prevState.openBookingModal,
    }));
  }

  handleSelectedLocation(value) {
    if (value) {
      let locationid = value.id;
      let locations = this.state.locationArray;
      for (let index = 0; index < locations.length; index++) {
        const element = locations[index];
        if (element._id === locationid) {
          this.setState({
            selectedLocation: element,
          });
          break;
        }
      }
    } else {
      this.setState({
        selectedLocation: "",
      });
    }
  }

  handleSelectedTime(time) {
    this.setState({
      selectedDateTime: time,
      openBookingModal: false,
    });
  }

  changeTypeSelected(value) {
    this.setState((prevState) => ({
      carTypeOptions: prevState.carTypeOptions.map((obj) =>
        obj.name === value
          ? Object.assign(obj, { isSelected: true })
          : Object.assign(obj, { isSelected: false })
      ),
    }));
    this.setState({
        selectedVehicleType: value
    })
  }

  handleBookingInputs(val) {
    val = val.target;
    this.setState((prevState) => {
      let userBillingDetails = Object.assign({}, prevState.userBillingDetails);
      console.log("val name:  ", val.name);
      if (val.name == "phone" || val.name == "carRego") {
        userBillingDetails[val.name] = val.value.replaceAll(" ", "");
      } else {
        userBillingDetails[val.name] = val.value;
      }
      return { userBillingDetails };
    });
  }
  
  async handleSelectedDate(date) {
    console.log("date: ", date);

    let data = {
        date: date,
        selectedLocation: this.state.selectedLocation._id,
    };

    let res = await api("location/availability", "POST", data);
    if (res.status === 200) {
        if (res.data.success) {
            let locationAvailability = res.data.locationAvailability;
            this.setState({
                locationAvailability: locationAvailability,
                availibilityInterval: res.data.interval,
                serviceSpecificCapacities: res.data.serviceSpecificCapacities,
            });
        }
    } else {
        console.log(
            "failed to get location availability after selecting date: ",
            res
        );
    }
  }

  selectServiceType(id, name, price, time, currentHour) {
    const vehicleType = this.state.selectedVehicleType
      .split(" ")[0]
      .toLowerCase();
    const servicePrice = price;
    this.setState({
      selectedService: id,
    });

    // this.props.handleSelectServiceType(name, id, servicePrice, currentHour);
  }

  async getLocations() {
    let res = await api("location/pricing", "GET", null);
    if (res.status === 200) {
      if (res.data.success) {
        let locations = res.data.pricing.map(function (item, index) {
          return { label: item.seoName, id: item._id };
        });
        this.setState({
          locations: locations,
          locationArray: res.data.pricing,
        });
      }
    } else {
      console.log("failed to get pricing: ", res);
    }
  }


  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const cartId = urlParams.get("cartId");

    if (cartId) {
      this.setState({ cartId });
      this.fetchAbandonedCart(cartId);
      this.getLocations();
      if (this.state.selectedVehicleType !== "") {
        this.setState((prevState) => ({
          carTypeOptions: prevState.carTypeOptions.map((obj) =>
            obj.name === this.state.selectedVehicleType
              ? Object.assign(obj, { isSelected: true })
              : Object.assign(obj, { isSelected: false })
          ),
        }));
      }
    } else {
      this.setState({ errorMessage: "Cart ID is missing. Please start a new booking." });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if selectedLocation has changed
    if (
      this.state.selectedVehicleType !== null &&
      this.state.selectedLocation &&
      this.state.selectedLocation._id !== (prevState.selectedLocation?._id || null)
    ) {
      this.getLocationPricing(this.state.selectedLocation._id);
    }
  }

  render() {
    const {
      loading,
      errorMessage,
      selectedVehicleType,
      serviceOptions,
      selectedService,
      selectedDateTime,
      openBookingModal,
      locationAvailability,
      availibilityInterval,
      serviceSpecificCapacities,
      discount,
      selectedLocation,
    } = this.state;

    const pricingOrder = [
        "Hand Polish",
        "Interior Detail",
        "Full Detail",
        "Paint Protection",
        "Express Wash",
        "Economy Wash",
        "Premium Wash",
        "Super Wash",
    ];
    const sortByObject = pricingOrder.reduce((obj, item, index) => {
        return {
            ...obj,
            [item]: index,
        };
    }, {});
    let pricingOptions = [];
    if (serviceOptions) {
        pricingOptions = serviceOptions.sort(
            (a, b) => sortByObject[a.name] - sortByObject[b.name]
        );
    }

    var allOptionsInactive = false;
    var countInactive = 0;
    var type = selectedVehicleType;

    for (let po = 0; po < pricingOptions.length; po++) {
        const priceOption = pricingOptions[po];
        type = type.toLowerCase().split(" / ")[0].trim();
        if (priceOption[type]) {
            if (priceOption[type].active == false) {
            countInactive++;
            } else if (priceOption[type].value == 0) {
            countInactive++;
            }
        } else {
            countInactive++;
        }
    }

    if (countInactive == pricingOptions.length) {
        allOptionsInactive = true;
    }
  

    if (errorMessage) {
        return (
          <div className="errorMessage">
            <div>
              <p>{errorMessage}</p>
              <button
                className="newBookingButton"
                onClick={() => (window.location.href = "/")} // Replace with the correct route
              >
                Start a New Booking
              </button>
            </div>
          </div>
        );
    }

    if (loading) {
      return <Loading />
    }

    const _this = this;

    return (
      <div className="resumeBooking">
        <h1>Continue Booking</h1>
        {
            selectedLocation ? (
                <>
                    <h3>Selected Location</h3>
                    <h5>{selectedLocation.seoName}</h5>
                </>
            ) : (
                <>
                    <h3>Select a Location</h3>
                    <div className="inputArea">
                        <Autocomplete
                        disablePortal
                        id="locationsInput"
                        options={this.state.locations}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                            <TextField {...params} label="Select a location" />
                        )}
                        onChange={(event, newValue) => {
                            this.handleSelectedLocation(newValue);
                        }}
                        value={
                            this.props.selectedLocationName !== ""
                            ? this.props.selectedLocationName
                            : ""
                        }
                        />
                        <div className="locationViewButton">
                        <p>Select</p>
                        </div>
                    </div>
                </>
            )
        }

        <div className="bookingTimeSection">
            <h3>Arrival Time</h3>
            <Button
              className="bookingTimeButton"
              onClick={this.handleBookingModal}
            >
              <span>
                {selectedDateTime
                  ? selectedDateTime
                  : "Select your booking date and time"}
              </span>
              <img
                src={Images.calendarIco}
                className="calendarIco"
                alt="calendar"
              />
            </Button>
        </div>
        <div className="vehicleTypeSection">
            <h3>Choose your vehicle type</h3>
            <div className="carTypeOptions">
              {this.state.carTypeOptions.map(function (item) {
                return (
                  <CardOption
                    item={item}
                    select={(item) => _this.changeTypeSelected(item)}
                    key={item.name}
                  />
                );
              })}
            </div>
        </div>
        <div className="serviceTypeSection">
            <>
                <h3>Choose Your Service</h3>
                <div className="serviceOptions">
                    <div className="bookingPriceGrid">
                        {pricingOptions !== null && pricingOptions.map((item, i) => {
                            return (
                                <BookingPricingCard
                                    key={i}
                                    item={item}
                                    selectedType={selectedVehicleType}
                                    dateRange={selectedDateTime}
                                    selectServiceType={(selectedItem, currentHour) => {
                                        var canSelect = false;
                                        if (
                                            serviceSpecificCapacities !==
                                                undefined &&
                                            serviceSpecificCapacities !== false
                                        ) {
                                        let serviceId = selectedItem._id;
                                        if (
                                            serviceSpecificCapacities[serviceId]
                                        ) {
                                            canSelect = false;
                                            var selectedTime = moment(
                                                selectedDateTime.split(" - ")[0],
                                            "MMM Do h:mmA"
                                            ).format("HH:mm");
                                            var availability =
                                            serviceSpecificCapacities[serviceId];
                                            for (
                                            let index = 0;
                                            index < availability.length;
                                            index++
                                            ) {
                                            const element = availability[index];
                                            if (element.timeStart == selectedTime) {
                                                canSelect = true;
                                                break;
                                            }
                                            }
                                        } else {
                                            canSelect = true;
                                        }
                                        } else {
                                        canSelect = true;
                                        }
                    
                                        if (canSelect) {
                                            this.selectServiceType(
                                                selectedItem._id,
                                                selectedItem.name,
                                                selectedItem.pricing,
                                                selectedItem.duration,
                                                currentHour
                                            );
                                        } else {
                                            this.displayServiceCapacityModal();
                                        }
                                    }}
                                    selectedService={selectedService}
                                    bundleSelected={null}
                                    selectedLocation={selectedLocation}
                                />
                            )}
                        )}
                    </div>
                </div>
            </>
        </div>
        <h2 className="confirmationHeadings">About you</h2>
        <div className="">
          <div className="flexRow">
            <div className="twoColumn">
              <input
                type={"text"}
                name={"firstName"}
                id={"bookingFirstName"}
                placeholder={"First name"}
                onChange={this.handleBookingInputs}
                value={
                  this.state.userBillingDetails &&
                  this.state.userBillingDetails.firstName
                }
              />
              {this.state.errorField.firstName ? (
                <p className="errorMess">{this.state.errorField.firstName}</p>
              ) : null}
            </div>
            <div className="twoColumn">
              <input
                type={"text"}
                name={"lastName"}
                id={"bookingLastName"}
                placeholder={"Last name"}
                onChange={this.handleBookingInputs}
                value={
                  this.state.userBillingDetails &&
                  this.state.userBillingDetails.lastName
                }
              />
              {this.state.errorField.lastName ? (
                <p className="errorMess">{this.state.errorField.lastName}</p>
              ) : null}
            </div>
          </div>
          <div className="flexRow">
            <div className="oneColumn">
              <input
                type={"text"}
                name={"phone"}
                id={"bookingPhone"}
                placeholder={"Phone"}
                onChange={this.handleBookingInputs}
                value={
                  this.state.userBillingDetails &&
                  this.state.userBillingDetails.phone
                }
              />
              {this.state.errorField.phone ? (
                <p className="errorMess">{this.state.errorField.phone}</p>
              ) : null}
            </div>
          </div>
          <div className="flexRow">
            <div className="oneColumn">
              <input
                type={"email"}
                name={"email"}
                id={"bookingEmail"}
                placeholder={"Email address"}
                onChange={this.handleBookingInputs}
                onKeyUp={this.removeWhieteSpace}
                value={
                  this.state.userBillingDetails &&
                  this.state.userBillingDetails.email
                }
              />
              {this.state.errorField.email ? (
                <p className="errorMess">{this.state.errorField.email}</p>
              ) : null}
            </div>
          </div>
          <div className="flexRow">
            <div className="oneColumn">
              <input
                type={"text"}
                name={"carRego"}
                id={"bookingRego"}
                autoComplete={"bookingRego"}
                placeholder={"Car rego number"}
                onChange={this.handleBookingInputs}
                onKeyUp={this.removeWhieteSpace}
                value={
                  this.state.userBillingDetails &&
                  this.state.userBillingDetails.carRego
                }
              />
              {this.state.errorField.carRego ? (
                <p className="errorMess">{this.state.errorField.carRego}</p>
              ) : null}
            </div>
          </div>
        </div>
        <Button
          className="completeBookingButton"
          onClick={this.completeBooking}
          variant="contained"
          color="primary"
        >
          Complete Booking
        </Button>
        <BookingTimeModal
          locationAvailability={locationAvailability}
          availibilityInterval={availibilityInterval}
          resetAvailability={() => this.setState({ locationAvailability: [] })}
          handleSelectedTime={this.handleSelectedTime}
          selectedBookingTime={selectedDateTime}
          selectedDate={this.handleSelectedDate}
          openBookingModal={openBookingModal}
          toggleDateModal={this.toggleDateModal}
          updateSelectedService={() => this.changeServiceSelected(selectedService)}
          discount={discount}
          location={selectedLocation}
        />
        {
            this.state.completeLoading && (
                <div className="overlay">
                    <Loading />
                </div>
            )
        }
      </div>
    );
  }
}

export default ResumeBooking;