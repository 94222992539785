import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import "./App.css";
import TagManager from "react-gtm-module";

import Maintenance from "./views/Maintenance";
import Pricing from "./views/Pricing";
import Booking from "./views/Booking";
import UpdateBooking from "./views/Booking/update";
import StripeBookingSuccess from "./views/Booking/stripeBookingSuccess";
import ResumeBookingSuccess from "./views/ResumeBooking/success";
import CanceledSubscription from "./views/Booking/canceledSubscription";
import PurchasePrepaid from "./views/Prepaid/";
import PurchaseSubscription from "./views/Subscription/";
import SubscriptionSuccess from "./views/Subscription/success";
// import PurchasePrepaidBundle from "./views/Prepaid/bundle";
import PurchaseBundle from "./views/Bundle/";
import BundleSuccess from "./views/Bundle/bundleSuccess";
import FreeExpressWash from "./views/FreeExpressWash";
import NotFound from "./views/404";
import ReactGA from "react-ga4";
import axios from "axios";
import ReactPixel from "react-facebook-pixel";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ResumeBooking from "./views/ResumeBooking";

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingId: null,
      isOffline: false,
      googleInitialise: false,
    };
    this.checkHealth = this.checkHealth.bind(this);
    this.checkOnlineStatus = this.checkOnlineStatus.bind(this);
    this.fbEvent = this.fbEvent.bind(this);
  }

  async checkHealth() {
    try {
      let health = await axios.get(
        "https://api.conciergecarwash.com.au/health"
      );
      console.log(health);
      if (health.status !== 200) {
        this.setState({
          isOffline: true,
        });
      }
    } catch (error) {
      this.setState({
        isOffline: true,
      });
    }
  }

  async checkOnlineStatus() {
    try {
      let health = await axios.get(
        "https://api.conciergecarwash.com.au/v1/maintenance/status"
      );
      console.log("check health: ", health);
      if (health.data.isOnline) {
        this.setState({
          isOffline: false,
        });
      } else {
        this.setState({
          isOffline: true,
        });
      }
    } catch (error) {
      this.setState({
        isOffline: true,
      });
    }
  }

  componentDidMount() {
    this.checkHealth();
    this.checkOnlineStatus();
    if (this.state.googleInitialise == false) {
      const tagManagerArgs = {
        gtmId: "GTM-WNGMZTK",
      };
      TagManager.initialize(tagManagerArgs);
      ReactPixel.init("323158478178364", {}, options);
      this.setState({
        googleInitialise: true,
      });
    }
    ReactPixel.pageView();
  }

  fbEvent = (event, data) => {
    ReactPixel.track(event, data);
  };

  render() {
    ReactGA.initialize("G-PM2TM03CWD");
    ReactGA.send({ hitType: "pageview", page: "/" });
    // For tracking page view
    return (
      //   <div className="App">
      //     <Nav />
      <Router>
        <Routes>
          {this.state.isOffline && (
            <Route
              exact
              path="/"
              element={<Maintenance ReactGA={ReactGA} GTM={TagManager} />}
            />
          )}
          <Route
            exact
            path="/pricing"
            element={<Pricing ReactGA={ReactGA} GTM={TagManager} />}
          />
          <Route
            exact
            path="/"
            element={<Booking ReactGA={ReactGA} GTM={TagManager} />}
          />
          <Route
            exact
            path="/manage-booking"
            element={<UpdateBooking ReactGA={ReactGA} GTM={TagManager} />}
          />
          <Route
            exact
            path="/resume-booking"
            element={<ResumeBooking ReactGA={ReactGA} GTM={TagManager} />}
          />
          <Route
            exact
            path="/success"
            element={
              <StripeBookingSuccess
                ReactGA={ReactGA}
                GTM={TagManager}
                fbEvent={this.fbEvent}
              />
            }
          />
          <Route
            exact
            path="/resume-success"
            element={
              <ResumeBookingSuccess
                ReactGA={ReactGA}
                GTM={TagManager}
                fbEvent={this.fbEvent}
              />
            }
          />
          <Route
            exact
            path="/canceledSubscription"
            element={
              <CanceledSubscription ReactGA={ReactGA} GTM={TagManager} />
            }
          />
          <Route
            exact
            path="/purchase-prepaid"
            element={<PurchasePrepaid ReactGA={ReactGA} GTM={TagManager} />}
          />
          <Route
            exact
            path="/purchase-subscription"
            element={
              <PurchaseSubscription ReactGA={ReactGA} GTM={TagManager} />
            }
          />
          <Route
            exact
            path="/subscription-payment-success"
            element={<SubscriptionSuccess ReactGA={ReactGA} GTM={TagManager} />}
          />
          {/* <Route exact path="/purchase-prepaid-bundle" element={<PurchasePrepaidBundle ReactGA={ReactGA} GTM={TagManager} />} /> */}
          <Route
            exact
            path="/purchase-bundle"
            element={<PurchaseBundle ReactGA={ReactGA} GTM={TagManager} />}
          />
          <Route
            exact
            path="/bundle-success"
            element={<BundleSuccess ReactGA={ReactGA} GTM={TagManager} />}
          />
          <Route
            exact
            path="/free-express-wash-giveaway"
            element={<FreeExpressWash ReactGA={ReactGA} GTM={TagManager} />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <ToastContainer newestOnTop />
      </Router>
      //     <Footer />
      //   </div>
    );
  }
}
export default App;
